.NavBar-container > ul{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style-type: none;
    background-color:#F5E6E0;
    font-weight: 600;
    font-size: 14px;
    height: 40px;
    margin: 0;
    padding: 0px;
}
li{
    padding: 10px;
}
.NavBar-container > ul > li > a{
    display: inline-block;
    color: #49535A;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
}

.NavBar-container {
    flex: 0 0 40px;
    height: 40px;
}

.NavBar{
    margin-left: 0px;
}