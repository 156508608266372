.watershed-reporting {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -100%;
    width: 100%;
    height: 100%;
    z-index: 10000;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(255, 255, 255, 0.8); */
    pointer-events: all;
    height: 500px;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: -5;
    grid-row-end: -5;
}

.watershed-reporting-content {
    // max-height: 50%;
    max-height: 500px;
    // width: 1000px;
    grid-column-start: 2;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    border: 2px solid silver;
    pointer-events: all;
    max-width: 620px;
    min-width: 620px;
    filter: drop-shadow(0 0 10px #000);
    border: none;

    background-color: #fff;
    box-sizing: border-box;
    color: #49535a;
    // max-height: 900px;
    padding-left: 30px;
    padding-right: 30px;

    overflow-y: scroll;
}

.watershed-reporting-export-container{
    margin-top: 20px;
    margin-bottom: 20px;
    // border: 2px solid red;
    margin-left: 10px;
    margin-right: 10px;
    background-image: linear-gradient(90deg,#618198,#97b7ce);

    p{
        color: white;
        font-weight: 700;
        font-size: 20px;
        text-align: left;
        margin-left: 20px;
    }
}

.watershed-reporting-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.watershed-icon{
    display: block;
    border: none;
    background-color: transparent;
    svg{
        height: 20px;
        color: white;
        font-weight: 700;
        height: 28px;
        width: 30px;
        cursor: pointer;
    }
}

.watershed-checkbox-container{
    display: flex;
    justify-content: start;
    flex-direction: column;
    margin-left: 25px;
    text-align: left;
    color: #384157;
}

.watershed-checkbox{
    display: flex;
}

.gen-watershed-report{
    margin-bottom: 10px;
    background-color: #ce8d85;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-family: Oswald,sans-serif;
    font-size: 16px;
    font-weight: 700;
    height: 40px;
    text-transform: uppercase;
}

.hide{
    display: hidden;
}

.selectGroupLabel{
    // font-weight: bold;
    align-items: center;
    border: none;
    // color: #618198;
    color: #384157;
    display: flex;
    font-size: 16px;
    font-weight: 300;
    height: 30px;
    padding: 0;
    font-weight: 700;
    cursor: pointer;
}

.light{
    background-color: #e7f2f7;
    color: #384157;
}

.dark{
    background-color: #b3d4e2;
    color: #384157;
}

.arrow{
    display: flex;
    align-items: center;
    transform: rotate(90deg);
    margin-right: 10px;
    margin-left: 10px;
    font-weight: 500;
}

.open{
    transform: rotate(180deg) !important;
}

#required{
    font-weight: 700;
    margin-left: 10px;
    text-align: right;
    font-style: italic;
    color: #384157;
}

#react-select-2-placeholder{
    color: black;
}

.clear-results{
    text-align: right;
    button{
        margin-bottom: 10px;
        background-color: #ce8d85;
        border: none;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        font-family: Oswald, sans-serif;
        font-size: 16px;
        font-weight: 700;
        height: 40px;
        text-transform: uppercase;
        width: 60px;
    }
}