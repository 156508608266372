/*COLORS*/
$blumine: #20558B;
$white: #FFF;
$black: #000;
$green-pea: #236430;
$mine-shaft: #313131;
$cod-gray: #121212;
$bay-leaf: #81B191;
$gray: #D9D9D9;
$silver: #CCCCCC;
$transparent: transparent;
$regular: 300;
$semi-bold: 600;
$bold: 700;

.maptools-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 10000;
    grid-column-start: -2;
    grid-column-end: -1;
    grid-row-start: 2;
    grid-row-end: -2;
    box-sizing: border-box;
    width: 100%;
    // background-color: rgba(255, 255, 255, 0.7);
    filter: drop-shadow(0 0 10px #000);
    clip-path: inset(0 round 0 0 0 20px);

    .maptools-header {
        background-image: linear-gradient(to right, #618198, #97b7ce);
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
    }

    .maptools-content {
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }
}

.maptools-container-hidden {
    transform: translateX(420px);
    transition: 200ms ease;
}

.maptools-container-shown {
    transform: translateX(0);
    transition: 200ms ease;
}

.maptools-button-open {
    transform: translateX(0px) rotate(-180deg);
    transition: all 200ms ease 0s;
    grid-area: 2 / -2 / 2 / -1;
    margin: 11px 27px 0 0;
    z-index: 10001;
    width: 23.8px;
    height: 21px;
    justify-self: end;
}

.maptools-button-closed {
    transform: translateX(0px) rotate(0);
    transition: all 200ms ease 0s;
    grid-area: 2 / -2 / 2 / -1;
    margin: 11px -2px 0 0;
    z-index: 10001;
    width: 23.8px;
    height: 21px;
    justify-self: end;
    filter: drop-shadow(0 0 10px #000);
}

#toolbarDiv {
  position: absolute;
  top: 200px;
  left: 15px;
  cursor: default;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  z-index: 999;
}

.esri-widget--button.active,
.esri-widget--button.active:hover,
.esri-widget--button.active:focus {
  cursor: default;
  background-color: #999696;
}
.esri-widget--button.active path,
.esri-widget--button.active:hover path,
.esri-widget--button.active:focus path {
  fill: #E4E4E4;
}
