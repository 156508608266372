/*COLORS*/
$blumine: #20558B;
$white: #FFF;
$black: #000;
$green-pea: #236430;
$mine-shaft: #313131;
$cod-gray: #121212;
$bay-leaf: #81B191;
$gray: #D9D9D9;
$silver: #CCCCCC;
$transparent: transparent;
$regular: 300;
$semi-bold: 600;
$bold: 700;

.popup-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: 100%;
    z-index: 10000;
    pointer-events: none;
    flex: 0 1 auto;
    box-sizing: border-box;

    .popup-content {
        flex-direction: column;
        background-color: white;
        pointer-events: all;
        filter: drop-shadow(0 0 10px #000);
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex: 1 1 auto;
        
        .popup-header{
            background-image: linear-gradient(to right, #618198, #97b7ce);
            padding: 10px;
            box-sizing: border-box;
            width: 100%;
        }

        .popup-accordion-container {
            box-sizing: border-box;
            width: 100%;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;

            .popup-accordion {
                align-items: center;
                background-color: #E7F2F7;
                border: none;
                // color: rgb(97, 129, 152);
                color: #384157;
                cursor: pointer;
                display: flex;
                font-family: "Oswald", sans-serif;
                font-size: 14px;
                font-weight: 300;
                outline: none;
                padding: 5px 10px;
                pointer-events: all;
                text-align: left;
                text-transform: uppercase;
                transition: 0.4s;
                width: 100%;
                flex: 0 0 auto;
                justify-content: space-between;
            }
            
            .popup-panel {
                background-color: white;
                overflow-x: hidden;
                overflow-y: auto;
                width: 100%;
                flex: 1 1 auto;
            }
        }
    }
}

.popup-container-hidden {
    transform: translateX(314px);
    transition: 200ms ease;
}

.popup-container-shown {
    transform: translateX(0);
    transition: 200ms ease;
}

.add-proj-screening{
    background-color: #CE8D85;
    border-radius: 7px;
    border: 0;
    padding: 5px 10px;
    color: #FFF;
    font-family: "Oswald", sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    pointer-events: all;
    margin-right: 15px;
}