.stream-header{
    width: 20%;
    text-align: left;
}

.stream-id-container{
    display: flex;
    justify-content: space-between;
}

.existing-data-row{
    background-color: #F5E6E0;
}

.proposed-data-row{
    background-color: #E7F2F7;
    input[type = number]{
        text-align: center;
    }
}