.storage-table{
    text-align: center;
    width: 100%;
    border-collapse: collapse;
}

.storage-table-data{
    background-color: #F5E6E0;
}

#storage-river-header{
    text-align: left;
}

.storage-proposed-data-row{
    background-color: #e7f2f7;
}

.storage-river-proposed, .storage-river-existing{
    display: flex;
    justify-content: space-between;
}

.storage-number-input{
    text-align: center;
}