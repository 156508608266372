.content{
    width: 100%;
    background: #FFF;
    padding-top: 10px;
    padding-bottom: 20px; 

    .project-planning-data{
        border-collapse: collapse;
        width: 100%;
        
        thead{
            tr{
                th{
                    font-family: "Oswald", sans-serif;
                    font-size: 14px;
                    color: #97B7CE;
                    text-transform: uppercase;
                    margin: 0;
                    padding: 5px 0;
                }
            }
        }

        tbody{
            tr{
                td{
                    margin: 0;
                    padding: 5px 0;
                }
            }
            .light-blue{
                background: #F7F9FA;
            }
        }
    }
    .MuiTableCell-body{
        height: 30px;
        padding: 0px;
    }
    .MuiTableCell-head{
        height: 35px;
        padding: 0px;
    }
    .MuiTableCell-footer > :first-child{
        height: 50px;
        padding: 0px;
    }
    .MuiTableCell-footer{
        overflow: hidden;
    }
    .MuiTablePagination-root:last-child {
        padding: 0;
        overflow: hidden;
    }
    .MUIDataTable-responsiveBase-5{
        overflow: hidden;
    }
    .button-container {
        font-family: "Oswald", sans-serif;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 auto;
        padding-bottom: 10px;
        padding-top: 10px;
        text-align: center;
        
        button {
            border-radius: 7px;
            border: 0;
            padding: 5px 10px;
            color: #FFF;
            font-family: "Oswald", sans-serif;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            cursor: pointer;
        }

        .primary {
            background: #CE8D85;
        }
    }

}



