.home {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 10000;
    text-align: center;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;

    .home-content {
        background-size: cover;
        border: 4px solid white;
        color: #FFF;
        display: flex;
        filter: drop-shadow(0 0 10px #000);
        flex-direction: column;
        font-family: "Roboto", sans-serif;
        pointer-events: all;
        text-align: left;
        padding: 30px;
        background-position: bottom;

        h1 {
            font-family: "Lora", sans-serif;
            margin-top:  0px;
        }

        .intro-text {
            margin-bottom: 20px;
        }

        .buttons {
            display: flex;
            justify-content: space-evenly;

            button {
                flex: 1 1 10;
                background: #CE8D85;
                padding: 10px 20px;
                border-radius: 5px;
                border: 0;
                padding: 5px 10px;
                margin-left: 2px;
                margin-right: 2px;
                color: #FFF;
                font-family: "Oswald", sans-serif;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                display: inline-block;
                cursor: pointer;
            }
        }
    }


}

.home-shroud {
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100%;
    z-index: 9999;
    pointer-events: all;
    grid-column-start: 1;
    grid-column-end: -1;
    grid-row-start: 1;
    grid-row-end: -1;
}