.modal-container{
    z-index: 10002;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
}

.inner-modal-container{
    background-color: white;
    margin-right: auto;
    margin-left: auto;
    margin-top: 100px;
    width: 30%;

    h1{
        color: #49535A;
        font-family: "Oswald", sans-serif;
        font-size: 23px;
        text-align: center;
    }
}

.inner-modal-container-calc{
    background-color: white;
    margin-right: auto;
    margin-left: auto;
    margin-top: 100px;
    width: 50%;
}

.wid_form{
    text-align: center;
    margin-bottom: 10px;
}

.close-icon{
    display: flex;
    justify-content: end;
    cursor: pointer;
    padding-top: 5px;
    padding-right: 5px;

    svg{
        height: 25px;
        width: 25px;
    }
}

#alert-instructions{
    margin-top: 0px;
}

.modal-button-container{
    display: flex;
    justify-content: center;

    button{
        font-family: "Oswald", sans-serif;
        background-color: #CE8D85;
        border-radius: 7px;
        border: 0;
        padding: 5px 10px;
        color: #FFF;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        pointer-events: all;
        margin-right: 15px;
        margin-bottom: 15px;
    }
}
.popup_id{
    padding-top: 5px;
    color: #49535a;
    font-weight: 700;
}

.ffi_id_popup{
    padding-right: 10px;
    padding-left: 10px;
}

.issue-key{
    font-weight: 700;
}