.project-manager {
    width: 100%;
    z-index: 10000;
    pointer-events: none;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: -2;
    display: flex;
    align-self: flex-start;
    max-height: 100%;
    

    .accordian-container{
        width: 420px;
        overflow-x: hidden;
        box-sizing: border-box;
        overflow-y: auto;
        padding-right: 10px;
    }

    .project-manager-content {
        flex-direction: column;
        justify-content: center;
        pointer-events: all;
        filter: drop-shadow(0 0 10px #000);
        display: flex;

        #main-container{
            display: flex;
            justify-content: space-between;
            max-height: 100%;

            .project-planning-modal {
                width: 420px;
                max-height: 100%;
                background-color: white;
                display: flex;
                flex-direction: column;

                .icon-title{
                    background-image: linear-gradient(to right, #618198, #97b7ce);
                    color: #FFF;
                    padding: 10px 25px;
                    position: relative;
                    width: 88%;

                    .icon_stage_three > svg{
                        height: 110px;
                        width: 110px;
                        position: absolute;
                        margin-left: auto;
                        margin-right: auto;
                        left: 0;
                        right: 0;
                        top: -60px;
                        text-align: center;
                    }
                }


                .instructions{
                    background-color: white;
                    padding-left: 30px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    width: 360px;

                    #project-planning{
                        font-size: 17px;
                        font-family: "Lora", sans-serif;
                        margin: 0 0 10px 0;
                        padding: 0;
                    }

                    p{
                        font-family: "Roboto", sans-serif;
                        font-size: 14px;
                        margin: 0;
                        padding: 0;
                    }
                }

                .form {
                    color: #49535A;
                    display: flex;
                    align-items: center;
                    padding: 0;
                    background-color: white;
                    flex-direction: column;
                    overflow-y: auto;
                    max-height: calc(100% - 189px);
                    overflow-x: hidden;

                    .question-container {
                        width: 420px;

                        .question {
                            width: 420px;

                            .accordian-container {

                                #section-1{
                                    background-color: #E7F2F7;
                                }
                                .section-2{
                                    background-color: #D1E8F2;
                                    
                                }
                                #section-3{
                                    background-color: #B3D4E2;
                                }

                                .accordion-button{
                                    background-color: #E7F2F7;
                                    // color: #618198;
                                    color: #384157;
                                    font-size: 18px;
                                    font-weight: 300;
                                    display: flex;
                                    align-items: center;
                                    height: 45px;
                                    border: none;
                                    padding: 15px;
                                    width: 420px;
                                    pointer-events: all;

                                    // svg{
                                    //     height: 22px;
                                    //     width: 22px;
                                    // }
                                }

                                .accordian{
                                    svg{
                                        height: 22px;
                                        width: 22px;
                                    }
                                }
                                

                                .selectCriteria {
                
                                    .selectGroupLabel {
                                        font-weight: bold;
                                        margin: 20px 0;
                                    }

                                    #applicability-container{
                                        margin-left: 20px;
                                    }
                
                                    .selectGroup {
                                        margin-left: 20px;
                                        margin-top: 10px;
                                    }
                                }
                            }
                        }
                    }

                    .button-container {
                        font-family: "Oswald", sans-serif;
                        font-size: 16px;
                        font-weight: 700;
                        text-transform: uppercase;
                        margin: 0 auto;
                        padding-bottom: 10px;
                        padding-top: 10px;
                        
                        button {
                            border-radius: 7px;
                            border: 0;
                            padding: 5px 10px;
                            color: #FFF;
                            font-family: "Oswald", sans-serif;
                            font-size: 16px;
                            font-weight: 700;
                            text-transform: uppercase;
                            cursor: pointer;
                        }
                
                        .primary {
                            background: #CE8D85;
                        }
                    }
                }
            }
        }
        #section-1, .section-2, #section-3, .section-4{
            background-color: #E7F2F7;
            // color: #618198;
            color: #384157;
            font-size: 18px;
            font-weight: 300;
            display: flex;
            align-items: center;
            height: 30px;
            border: none;
            padding: 15px;
            width: 420px;
            pointer-events: all;
        }
        
        .panel > p{
            display: inline-block;
            margin: 0;
            padding: 0 12px;
        }
        .panel{
            padding: 30px;
            background-color: white;
            overflow: hidden;
            width: 360px;
        }
        .side-panel{
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            right: 0px;
            pointer-events: all;
        }
       
        
        #project-planning{
            font-size: 17px;
            font-family: "Lora", sans-serif;
            margin: 0 0 20px 0;
            padding: 0;
        }
        p{
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            margin: 0;
            padding: 0;
        }
        .panel > p{
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            margin: 0;
            padding: 0;
        }
        svg{
            margin-right: 5px
        }
        
        .project-planning-table-3{
            background: #FFF;
            display: flex;
            align-items: center;
            font-family: "Oswald", sans-serif;
            font-size: 16px;
            font-weight: 700;
            height: 60px;
            padding: 0 0 0 20px;
            text-transform: uppercase;
            color: #49535A;
            width: 400px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            justify-content: space-around;
            margin-top: 30px;
        }
        .title-tab_3 > svg{
            height: 40px;
            width: 40px;
            margin-right: 5px;
        }
        
        .title-tab_3{
            display: flex;
            align-items: center;
        }
        #toggleable-div-3{
            background: #FFF;
            display: flex;
            align-items: center;
            height: 150px;
            padding: 20px;
            color: #49535A;
            width: 800px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            justify-content: flex-start;
            pointer-events: all;
        }
        .outcome-table-3{
            cursor: pointer;
        }
        .accordian, .button-container, .project-planning-table-3{
            pointer-events: all;
        }
        .selectGroupLabel {
            font-weight: bold;
            margin-top: 20px;
            margin-left: 5px;
        }
        .selectItem{
            margin-left: 10px;
            display: flex;
        }
        .accordian {
            svg{
                height: 22px;
                width: 22px;
            }
        }
        #project-priority-container, #applicability-container{
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .selectCriteria{
            margin-left: 20px;
            margin-bottom: 15px;
        }
    }
}

.project-manager-hidden {
    transform: translateX(-420px);
    transition: 200ms ease;
}

.project-manager-shown {
    transform: translateX(0);
    transition: 200ms ease;
}


