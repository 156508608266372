.slider-container{
    // grid-column-start: 1;
    // grid-column-end: -1;
    height: 100%;
    // overflow-y: scroll;
    z-index: 10001;
    position: absolute;
    width: 100%;
    pointer-events: none;
}
