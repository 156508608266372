.selected{
    background-color: rgb(237, 210, 197) !important;
}

.template-container{
    padding-bottom: 20px;
}

.hide{
    display: none;
}

.popup-content-container{
    display: flex;
}

.category-rows{
    display: flex;
    // justify-content: space-between;
    align-items: center;
    height: 20px;
    padding: 10px;

    .label-text-key{
        font-size: 11px;
        width: 200px;
        // width: 50%;
    }

    .label-text-value{
        font-size: 11px;
        width: 40px;
        padding-left: 30px;
    }

}

.project-row{
    height: 20px;
    padding: 10px;

    p{
        font-size: 11px;
        // width: 50%;
    }
}

.label-text{
    margin-right: 20px;
}

.shade{
    background-color: #EAEAEA;
}

.header-tabs{
    height: 50px;
}

.tab-text{
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 30px;
    background-color: #F5E6E0;

    h5{
        font-size: 13px;
        padding-left: 10px;
        font-weight: 700;
    }
}

.category-section > h2{
    font-size: 20px;
}

.layer-title{
    font-size: 14px;
    font-weight: 700;
}

.id_headers{
    font-size: 11px;
    font-weight: 700;
}

.id_headers_values{
    font-size: 11px;
    font-weight: 700;
    font-style: italic;
}

.closed-arrow{
    display: flex;
    align-items: center;
    transform: rotate(90deg);
    margin-right: 10px;
    margin-left: 10px;
    font-size: 13px;
}

.open-arrow{
    display: flex;
    align-items: center;
    transform: rotate(180deg);
    margin-right: 10px;
    margin-left: 10px;
    font-size: 13px;
}

.title-section{
    padding-right: 20px;
    padding-left: 20px;
}
