.App{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #E7F2F7;
}

.MainContentWrapper {
    /* flex: 1 1 100%; */
    display: grid;
    grid-template-columns: minmax(260px, 420px) minmax(250px, 1fr) minmax(250px, 1fr) minmax(260px, 420px);
    grid-template-rows: 100px 100px 100px 100px 1fr 50px;
    gap: 2px 0px;
    height: 100%;
    width: 100%;
    min-height: 500px;
    overflow: hidden;
}

.hide-upper-node{
    display: flex;
    align-items: end;
    flex-direction: column;
}

.slider-nav-buttons{
    height: 30px;
    width: 30px;
    pointer-events: all;
    margin-right: 20px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4d4d4d;;
    border: rgb(97, 129, 152);
}

.slider-nav-buttons > span{
    font-size: 30px;
    color: white
}

iframe{
    display: none !important;
}

.esri_attribution_link{
    pointer-events: none;
}