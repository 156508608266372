// *{
//     display: none;
// }
// #section-1{
//     background-color: #E7F2F7;
//     color: #618198;
//     font-size: 18px;
//     font-weight: 300;
//     display: flex;
//     align-items: center;
//     height: 45px;
//     border: none;
//     padding: 15px;
// }

// .section-2{
//     background-color: #D1E8F2;
//     color: #618198;
//     font-size: 18px;
//     font-weight: 300;
//     display: flex;
//     align-items: center;
//     height: 45px;
//     border: none;
//     padding: 15px
// }

// .section-3, .section-4{
//     background-color: #B3D4E2;
//     color: #618198;
//     font-size: 18px;
//     font-weight: 300;
//     display: flex;
//     align-items: center;
//     height: 45px;
//     border: none;
//     padding: 15px;
// }
// .accordian-container{
//     margin-left: 32px;
//     margin-top: 16px;
//     overflow-y: auto;
//     max-height: 300px;

//     .accordion-button {
//         cursor: pointer;
//     }
// }

// .accordion-container {
//     overflow-y: auto;
//     max-height: 200px;
//     width: 420px;
//     overflow-x: hidden;
//     box-sizing: border-box;

//     #section-1{
//         background-color: #E7F2F7;
//     }
//     .section-2{
//         background-color: #D1E8F2;
        
//     }
//     .section-3{
//         background-color: #B3D4E2;
//     }
// }

//     .accordion-button{
//         background-color: #E7F2F7;
//         color: #618198;
//         font-size: 18px;
//         font-weight: 300;
//         display: flex;
//         align-items: center;
//         height: 45px;
//         border: none;
//         padding: 15px;
//         width: 420px;
//         pointer-events: all;
//     }
    

//     .selectCriteria {
//         margin-left: 20px;
//         margin-bottom: 10px;

//         .selectGroupLabel {
//             font-weight: bold;
//             margin: 10px 0;
//         }

//         .selectGroup {
//             margin-left: 20px;
//             margin-top: 10px;
//         }

//         .selectItem {

//         }
//     }
// }

#section-4{
    padding-left: 20px;
    // span{
    //     margin-left: 25px;
    // }
}

#applicability-container{
    div{
        margin-left: 10px;
    }
}