.stream-data-row{
    background-color: #F5E6E0;
    margin-bottom: 10px;

    td{
        height: 30px;
        padding-bottom: 1em;
    }
}

.stream-data-row.spaceUnder>td {
    padding-bottom: 1em;
  }

.stream-stability-table{
    text-align: center;
    border-collapse: collapse;
    width: 100%;
}

.river-stability-id-header{
    width: 20%;
    text-align: left;
}

.river-proposted-data-row_id, .river-existing-data-row_id{
    display: flex;
    justify-content: space-between;
}

.river-proposted-data-row{
    background-color: #E7F2F7;
    input[type=number]{
        text-align: center;
    }
}

.river-existing-data-row{
    background-color: rgb(245, 230, 224);
}

.hide{
    display: none;
}