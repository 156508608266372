.project-manager-start {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 10000;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 5;
    align-self: flex-start;

    .project-manager-start-content {
        flex-direction: column;
        justify-content: center;
        background-color: white;
        pointer-events: all;
        // width: 800px;
        filter: drop-shadow(0 0 10px #000);
    
        .info-section{
            background-image: linear-gradient(to right, #618198, #97b7ce);
            color: #FFF;
            padding: 30px;
            position: relative;
        }
        
        .modal-container-1{
            pointer-events: all;
            /* box-shadow: 0 0 10px black; */
            filter: drop-shadow(0 0 10px #000);
        }
        
        .button-container{
            display: flex;
            justify-content: left;
            align-items: center;
            font-family: "Oswald", sans-serif;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            background-color: white;
            padding: 30px;
        }
        
        .button-container > button{
            background-color: #CE8D85;
            border-radius: 7px;
            border: 0;
            padding: 5px 10px;
            color: #FFF;
            font-family: "Oswald", sans-serif;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            cursor: pointer;
            height: unset;
        }
        
        #or{
            margin-right: 20px;
            margin-left: 20px;
            color: #49535A;
        }
        
        .icon_stage_one > svg{
            height: 200px;
            width: 200px;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            top: -110px;
            text-align: center;
        }
        #modal-container-text > p{
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            margin: 0;
            padding: 0;
        }
        .info-section > h3{
            font-size: 24px;
            font-family: "Lora", sans-serif;
            margin: 40px 0 20px 0;
            padding: 0;
        }
    }
}



