.submit-project {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -100%;
    width: 100%;
    height: 100%;
    z-index: 10000;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(255, 255, 255, 0.8); */
    pointer-events: none;
}

.submit-project-content {
    max-height: 50%;
    width: 1000px;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border: 2px solid silver;
    pointer-events: all;
}