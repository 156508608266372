.summary-container, .inner-summary-container{
    width: 75%;
    text-align: right;
}

.stream-summary-header{
    padding: 20px;
    background-color: #E7F2F7;
    text-align: left;
    font-weight: 700;
}

.river-summary-header{
    background-color: #F5E6E0;
    padding: 20px;
    text-align: left;

    font-weight: 700;
}
.summary-container{
    // background-color: #EBEBEB;
    margin-right: auto;
    margin-left: auto;
    padding: 5px;
    margin-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 90%;
}

.inner-summary-container{
    margin-right: auto;
    margin-left: auto;
}

.inner-summary-gray-row{
    background-color: #EBEBEB;
    height: 30px;
}