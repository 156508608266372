@keyframes spinner {
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
}

#spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    pointer-events: all;
    grid-column-start: 1;
    grid-column-end: -1;
    grid-row-start: 1;
    grid-row-end: -1;
    z-index: 100000;
    background-color: rgba(0, 0, 0, .5);
}

#spinner {
    /* padding: 0;
    margin: 0; */
    min-width: 100px;
    min-height: 100px;
    border: 5px solid rgba(255,255,255,.1);
    border-right: 5px solid blue;
    // border-right: 5px solid #CE8D85;

    border-radius: 50%;
    animation: spinner 1s linear infinite;
    z-index: 2;
    position: absolute;
}