.table-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 10000;
    justify-content: flex-start;
    align-items: flex-start;
    // pointer-events: none;
    grid-column-start: 1;
    grid-column-end: -1;
    // grid-row-start: -5;
    // grid-row-end: -1;
    pointer-events: all;
    // overflow-y: auto;

    .table-content {
        flex-direction: column;
        pointer-events: all;
        filter: drop-shadow(0 0 10px #000);
        width: 100%;
        max-height: 98%;

        .table-tabs {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background-color: rgba(255, 255, 255, 0);
            // border-bottom: 1px solid silver;

            .title-tab {
                // cursor: pointer;
                flex: 1 1 auto;
                margin: 0 5px;
                background-color: silver;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                height: 46px;
                vertical-align: middle;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Oswald", sans-serif;
                text-transform: uppercase;
                display: flex;
                justify-content: space-around;
                text-align: center;

                svg {
                    width: 30px;
                    height: 30px;
                    margin: 0 10px;
                }
            }

            .highlight{
                border-top: 5px solid gold;
                border-right: 5px solid gold;
                border-left: 5px solid gold;
            }
        }

        .table-data {
            background-color: #EBEBEB;
            padding: 20px;
            // height: calc(100% - 86px);
            // overflow-y: auto;

            .table {
                // overflow-y: auto;
                // background-color: white;
                // border: 3px solid white;
                background-color: #EBEBEB;
                font-size: 14px;
            }
        }

        #outcome-planning-table:hover{
            cursor: pointer;
        }

        #cost-effectiveness{
            cursor: pointer;
        }

        #not-allowed:hover{
            cursor: not-allowed
        }

        
        #project-planning-itle-button{
            cursor: pointer;
        }

        #project-summary-container, #project-outcome-container{
            background-color: white;
            display: flex;
            flex-direction: column;
            padding-left: 20px;
            padding-right: 10px;
        }
        .individual-project-info{
            display: flex;
            justify-content: space-between;
        
        }
        .project-headers{
            font-family: "Oswald", sans-serif;
            font-size: 16px;
            font-weight: 700;
        }
        .individual-project-info > p{
            margin-right: 20px;
        }
    }

}
#outcome-planning-table:hover{
    cursor: pointer;
}

#not-allowed:hover{
    cursor: not-allowed
}

#project-table-tab:hover{
    cursor: pointer;
}
#allowed-cursor:hover{
    cursor: pointer;
}
#phos-credit-calc-tab:hover{
    cursor: pointer;
}
#wq-benefit-tab:hover{
    cursor: pointer;
}
#not-allowed-cursor:hover{
    cursor: not-allowed;
}
#resiliency-benefit-tab{
    cursor: pointer;
}

#explore-outcomes-button {
    border-radius: 7px;
    border: 0;
    padding: 5px 10px;
    color: #FFF;
    font-family: "Oswald", sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    background-color:#CE8D85;
    pointer-events: all;
    text-align: center;
}

.river_corr_div{
    background-color: #F5E6E0;
    height: 90px;
    font-weight: 700;
    // align-items: center;
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
}

.dynm_table{
    // display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    // width: 1700;
    // background-color: #EBEBEB;
    height: 40px;
    // align-items: center;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
}

.river-segment-header{
    // display: flex;
    height: 40px;
    // align-items: center;
    // background-color: #EBEBEB;
    background-color: #F5E6E0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-bottom: 10px;
}

.table-spacing{
    margin-right: 15px;
    margin-left: 15px;
    font-size: 13px;
    text-align: center;
    margin-top: 0px;
    align-self: center;
    // white-space: nowrap;
}
// .rc-table-spacing{
//     margin-right: 100px;
//     margin-left: 100px;
// }

.table-description{
    margin-right: 5px;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 700;
}

@media only screen and (max-width: 1300px){
    .table-spacing{
        margin-right: 5px;
        margin-left: 5px;
        font-size: 12px;
        text-align: right;
        align-self: left;
    }
}

.rc-table-spacing-1{
    // margin-right: 100px;
    // width: 340px;
    display: flex;
    align-items: center;
    grid-column-start: 1;
    grid-column-end: 1;
    text-align: left;
    width: 250px;
}
.rc-table-spacing-2{
    // margin-right: 200px;
    // width: 340px;
    grid-column-start: 2;
    grid-column-end: 2;
    margin-right: 0px;
    margin-left: 0px;
}
.rc-table-spacing-3{
    // margin-right: 200px;
    // width: 340px;
    grid-column-start: 3;
    grid-column-end: 3;
}
.rc-table-spacing-4{
    // margin-right: 200px;
    // width: 340px;
    grid-column-start: 4;
    grid-column-end: 4;
    margin-right: 0px;
    margin-left: 0px;
}
.rc-table-spacing-5{
    grid-column-start: 5;
    grid-column-end: 5;
}
.rc-table-spacing-6{
    grid-column-start: 6;
    grid-column-end: 6;
}
.rc-table-spacing-7{
    grid-column-start: 7;
    grid-column-end: 7;
}
.rc-table-spacing-8{
    grid-column-start: 8;
    grid-column-end: 8;
    margin-right: 0px;
    margin-left: 0px;
}

// .rc-table-spacing-9{
//     grid-column-start: 9;
//     grid-column-end: 9;
//     white-space: nowrap;
// }

.rc-table-spacing-9, .stream-segment-content-input{
    margin: auto;
}

// .rc-table-spacing-5{
//     margin-right: auto;
//     margin-left: auto;
//     width: 340px;
// }

.arrow{
    display: flex;
    align-items: center;
    transform: rotate(90deg);
    margin-right: 10px;
    margin-left: 10px;
}

.arrow-open{
    display: flex;
    align-items: center;
    transform: rotate(180deg);
    margin-right: 10px;
    margin-left: 10px;
}

.gen-sub-section{
    // display: flex;
    // justify-content: space-evenly;
    // background-color: #EBEBEB;
    height: 25px;
    // align-items: center;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size: 14px;
    padding-bottom: 20px;
    text-align: center;
}

.stream-segment-header{
    // display: flex;
    height: 30px;
    // align-items: center;
    background-color: #E7F2F7;
    // justify-content: space-evenly;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    padding-bottom: 15px;
    padding-top:  10px;
    font-size: 15px;
    text-align: center;
    align-items: flex-start;
    padding-bottom: 20px;
}
.stream-segment-content{
    // display: flex;
    // justify-content: space-evenly;
    // background-color: #CE8D85;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: white;
    // background-color: #EBEBEB;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
}
.st-projects-header{
    // display: flex;
    font-weight: 700;
    // background-color: #EBEBEB;
    background-color: #E7F2F7;
    margin-left: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.st-projects-content{
    // display: flex;
    align-items: center;
    height: 50px;
    background-color: white;
    margin-left: 50px;
    // background-color: #EBEBEB;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    font-size: 14px;
}

.hide{
    display: none !important;
}

.rc_section{
    padding-right: 30px;
    padding-left: 30px;
}

.rc-table-spacing{
    // width: 275px;
    font-weight: 700;
    font-size: 13px;
    text-align: center;
}

.gen-sub-section-input{
    // width: 250px;
    // text-align: center;
    margin-right: auto;
    margin-left: auto;
    align-self: center;
}
// .gen-sub-section-text{
//     width: 250px;
//     padding-left: 30px;
// }
.arrow-container{
    display: flex;
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.stream-segment-header > p{
    margin-right: auto;
    margin-left: auto;
    font-weight: 700;
    font-size: 13px;
    // padding-right: 5px;
    // padding-left: 5px;
    // margin-top: 0px;
    // align-self: center;
    // align-items: start;
}
.stream-segment-content > p{
    // width: 200px;
    margin-right: auto;
    margin-left: auto;
}
.st-projects-header > p, .st-projects-content > p{
    // width: 200px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}
.st-projects-header > p{
    font-size: 13px;
}
.st-projects-header:first-child{
    text-align: left !important;
}
.st-projects-contents-input{
    // width: 175px;
    // text-align: center;
    margin-right: auto;
    margin-left: auto;
}
// .st-projects-contents-text{
//     width: 175px;
//     padding-left: 50px;
//     text-align: left;
// }
.filler{
    margin-left: 50px;
}

.gen-sub-section-action{
    display: flex;
    align-items: center;
    justify-content: right;
}

.outline > svg{
    height: 30px;
    width: 30px;
    margin-right: 5px;
    margin-left: 5px;
}
.magnify > svg{
    height: 20px;
    width: 20px;
    margin-right: 5px;
    margin-left: 5px;
}
.table-spacing-1{
    grid-column-start: 1;
    grid-column-end: 1;
    width: 200px;
    text-align: left;
}
.table-spacing-2{
    grid-column-start: 2;
    grid-column-end: 3;
}
.table-spacing-3{
    grid-column-start: 3;
    grid-column-end: 3;
}
.table-spacing-4{
    grid-column-start: 4;
    grid-column-end: 4;
}
// .table-spacing-9{
    // align-self: start;
    // grid-column-start: 9;
    // grid-column-end: 9;
    // white-space: nowrap;
    // text-align: left;
    // padding-bottom: 5px;
    // font-size: 14px;
// }
.rc-sub-table-spacing-1, .gen-sub-section-content-1{
    grid-column-start: 1;
    grid-column-end: 1;
    text-align: left;
    margin-left: 20px;
}

.rc-sub-table-spacing-2, .gen-sub-section-content-2{
    grid-column-start: 2;
    grid-column-end: 2;
}
.rc-sub-table-spacing-3, .gen-sub-section-content-3{
    grid-column-start: 3;
    grid-column-end: 3;
}
.rc-sub-table-spacing-4, .gen-sub-section-content-4{
    grid-column-start: 4;
    grid-column-end: 4;
}
.rc-sub-table-spacing-5{
    grid-column-start: 5;
    grid-column-end: 5;
}
.rc-sub-table-spacing-6, .gen-sub-section-content-6{
    grid-column-start: 6;
    grid-column-end: 6;
}
.no-wrap-text{
    white-space: nowrap; 
}
.center{
    text-align: center;
}
#st-projects-header-proj-id, #st-proj-id-content, #st-projects-header-proj-type, #st-proj-type-content{
    margin-left: 0px;
    padding-left: 20px;
    text-align: left;
}

.clear-proj-screening-button, .expand-all-button, .export-button{
    background: #ce8d85;
    border-radius: 5px;
    border: 0;
    padding: 5px 10px;
    margin-left: 2px;
    margin-right: 2px;
    color: #fff;
    font-family: "Oswald",sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
}

.export-button-disabled{
    background: #4d4d4d7a;
    border-radius: 5px;
    border: 0;
    padding: 5px 10px;
    margin-left: 2px;
    margin-right: 2px;
    color: #fff;
    font-family: "Oswald",sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    cursor: not-allowed;
}

.clear-button-container{
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
}

#reverse-chevron{
    transform: rotate(0.5turn);
}

#reverse-chevron, #chevron{
    height: 20px !important;
    polygon{
        fill: #618198;
    }
}

.phos-credit-calc-row{
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
}

.phos-credit-calc-header{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background-color: #F5E6E0;
}

.calc-button{
    height: 40px;
    border-radius: 7px;
    border: 0;
    padding: 5px 10px;
    background-color: rgb(237, 210, 197);
    font-family: "Oswald", sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    border: 2px solid;
}

h3{
    font-size: 15px;
}

.subtable-tabs-container{
    display: flex;

    .sub-table-tab{
        display: flex;
        flex: 1 1 auto;
        margin: 0 5px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        height: 35px;
        vertical-align: middle;
        font-family: "Oswald", sans-serif;
        text-transform: uppercase;
        display: flex;
        justify-content: space-around;
        background-color: white;
        align-items: center;
        border: 2px solid black;
        margin-top: 20px;
        cursor: pointer;
    }
}

.checkbox-row{
    white-space: nowrap;
}

.checkbox-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

#phos-credit-calc-table{
    background-color: white;
}

.checkbox-container-with-title{
    padding-right: 20px;
    padding-left: 30px;
}

#phos-credit-calc-container{
    overflow-x: scroll;
}

.wq-button-container{
    text-align: right;
}