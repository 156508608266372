


#color-1{
    background-color: #E7F2F7;
    width: 100%;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
}
#color-2{
    background-color: #D1E8F2;
    width: 100%;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
}
#color-3{
    background-color: #B3D4E2;
    width: 100%;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
}
#color-4{
    background-color: #E7F2F7;
    width: 100%;
    margin: 0;
    padding: 10px;
    box-sizing: border-box;
}
#project-info-button, .toggle-buttons{
    background: #CE8D85;
    border-radius: 7px;
    border: 0;
    padding: 5px 10px;
    color: #FFF;
    font-family: "Oswald", sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    // cursor: pointer;
}
#headers-style > td{
    font-family: "Oswald", sans-serif;
    font-size: 14px;
    color: #97B7CE;
    text-transform: uppercase;
    margin: 0;
    padding: 5px 0;
}

.projectsWrapper {
    max-height: 520px;
    overflow-y: auto;
    margin-top: 10px;
    border: 1px solid lightgray;
    border-radius: 10px;

}

.table-heading {
    font-weight: bold;
}

.results-table {
    width: 100%;
}

.table-section {
    margin: 10px;
    padding: 10px;
    border: 1px solid silver;
    border-radius: 10px;
}

.table-results{
    justify-content: space-between;
    text-align: center;
}