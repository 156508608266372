/*COLORS*/
$blumine: #20558B;
$white: #FFF;
$black: #000;
$green-pea: #236430;
$mine-shaft: #313131;
$cod-gray: #121212;
$bay-leaf: #81B191;
$gray: #D9D9D9;
$silver: #CCCCCC;
$transparent: transparent;
$regular: 300;
$semi-bold: 600;
$bold: 700;

.search-container  {
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: 100%;
    z-index: 10000;
    pointer-events: none;
    flex: 0 1 1px;
    box-sizing: border-box;

    .search-content {
        flex-direction: column;
        background-color: white;
        pointer-events: all;
        filter: drop-shadow(0 0 10px #000);
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex: 1 1 auto;
        
        .search-header{
            background-image: linear-gradient(to right, #618198, #97b7ce);
            padding: 10px;
            box-sizing: border-box;
            width: 100%;
        }

        .search-accordion-container {
            box-sizing: border-box;
            width: 100%;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;

            .search-accordion {
                align-items: center;
                background-color: #E7F2F7;
                border: none;
                // color: rgb(97, 129, 152);
                color: #384157;
                cursor: pointer;
                display: flex;
                font-family: "Oswald", sans-serif;
                font-size: 14px;
                font-weight: 300;
                outline: none;
                padding: 5px 10px;
                pointer-events: all;
                text-align: left;
                text-transform: uppercase;
                transition: 0.4s;
                width: 100%;
                flex: 0 0 auto;
            }
            
            .search-panel {
                background-color: white;
                overflow-x: hidden;
                overflow-y: auto;
                width: 100%;
                flex: 1 1 auto;
            }
        }
    }

    // .esri-search__container {
    //     flex-flow: column nowrap;
    // }

    // .esri-search__input-container {
    //     flex-flow: column nowrap;
    // }

    // .esri-menu {
    //     position: unset;
    //     top: unset;
    //     left: unset;
    // }
}

#searchWidget {
    width: unset;
}

.esri-search__container .esri-search__input {
    z-index: 0;
    width: 100%;
}

.esri-search__form {
    padding: 0;
    margin: 0;
    align-items: stretch;
    flex: 2 0;
    width: 100%;
}

.esri-search__container, .esri-search__input-container, .esri-search__form {
    display: flex;
    flex-flow: row wrap;
}

.esri-search__input-container {
    align-items: stretch;
    align-self: start;
    flex: 2 0 auto;
    width: 100%;
}

.esri-menu {
    position: relative;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 1px 2px rgb(0 0 0 / 30%);
    padding: 0;
    margin: 2px 0 0 0;
    font-size: 14px;
    line-height: 16px;
    -moz-background-clip: padding;
    background-clip: padding-box;
    overflow: hidden;
    width: 100%;
    visibility: hidden;
    max-height: 0;
}

.esri-search__container .esri-search__input, .esri-search__container .esri-widget--button {
    z-index: 0;
    width: 100%;
}