.cost-effect-container{
    background-color: #ffffff;
    padding: 20px;

    table{
        border-collapse: collapse;
        width: 100%;
    }
}

.cost-table-header{
    background-color: #F5E6E0;
    height: 50px;
}

.cost-table-body{
    text-align: center;
    tr{
        background-color: #E7F2F7; 
        height: 50px;
    }
    .gray{
        background-color: #EBEBEB;
    }
}

#cost-effect-link{
    color: rgb(97, 129, 152);
}