.project-manager-filter {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 10000;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 6;
    box-sizing: border-box;

    .project-manager-filter-content {
        flex-direction: column;
        justify-content: center;
        background-color: white;
        pointer-events: all;
        width: 100%;
        box-sizing: border-box;
        
        filter: drop-shadow(0 0 10px #000);
        
        .project-filter-header {
            background-image: linear-gradient(to right, #618198, #97b7ce);
            color: #FFF;
            padding-right: 30px;
            padding-left: 30px;
            padding-top: 5px;
            padding-bottom: 5px;
            position: relative;
            width: 100%;
            height: 60px;
            box-sizing: border-box;
            
            h3{
                font-size: 24px;
                font-family: "Lora", sans-serif;
                margin: 12px 0 20px 0;
                padding: 0;
            }

            .icon_project-filter-header {
                svg{
                    height: 200px;
                    width: 200px;
                    position: absolute;
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    top: -110px;
                    text-align: center;
                }
            }
            
        }

        .filters-container {
            color: #49535A;
            display: flex;
            padding-right: 30px;
            padding-left: 30px;
            padding-bottom: 5px;
            background-color: white;
            flex-direction: column;
            width: 100%;
            pointer-events: all;
            max-height: 900px;
            box-sizing: border-box;

            .numbered-list{
                border: 1px solid #D9D9D9;
                border-radius: 50%;
                // color: #D9D9D9;
                color: #404040;
                padding: 5px 10px;
            }

            .filter-scale {
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                .selectMenu {
                    width: 55%;
                }

                input{
                    padding: 12px 20px;
                    border: 1px solid #D9D9D9;
                    border-radius: 4px;
                    font-size: 16px;
                    resize: vertical;
                }
        
                select{
                    width: 200px;
                    height: 35px;
                }
                label{
                    align-self: center;
                }

                button{
                    background-color: #618198;
                    color: white;
                    border-radius: 4px;
                    border: none;
                    font-size: 15px;
                    font-weight: 700;
                }
            }

            .filter-region{
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                .selectMenu {
                    width: 50%;
                }

                select{
                    width: 150px;
                    height: 35px;
                }
                label{
                    align-self: center;
                }
            }
            
            .filter-criteria {
                display: flex;
                justify-content: space-between;
                margin: 0 0 0 0;
                align-items: center;
            }

            .questionThree, .questionFour{
                margin: 0 0 10px 0;
                align-items: center;
            }

            .submit-button-container{
                margin-left: 25px;
                margin-top: 5px;
                text-align: center;
                button{
                    background-color: #CE8D85;
                    border: none;
                    color: white;
                    font-family: "Oswald", sans-serif;
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: uppercase;
                    height: 40px;
                    border-radius: 4px;
                    cursor: pointer;
                }
            }

            .criteria {
                margin: 0;
                padding: 0;
            }

            .accordian-container {
                margin-left: 32px;
                //margin-top: 16px;
                overflow-y: auto;
                // max-height: 180px;
                max-height: 400px;
                display: flex;
                flex-direction: column;
                p {
                    margin-right: 10px;
                    margin-left: 10px;
                }
    
                .selectCriteria {
                    margin-left: 20px;
                    margin-bottom: 10px;
    
                    .selectGroupLabel {
                        font-weight: bold;
                        margin: 10px 0;
                        color: #384157;
                    }
    
                    .selectGroup {
                        margin-left: 20px;
                        margin-top: 10px;
                    }
                }
    
                .selectGroupLabel {
                    font-weight: bold;
                    margin: 10px 0;
                }
    
                .selectGroup {
                    margin-left: 20px;
                }

                .accordion-button {
                    cursor: pointer;
                }
    
                .criteriaSection {
                    // color: #618198;
                    color: #384157;
                    font-size: 16px;
                    font-weight: 300;
                    display: flex;
                    align-items: center;
                    height: 30px;
                    border: none;
                    padding: 0;
                    svg{
                        margin-right: 9px
                    }
                }

                .functionsCriteriaGroup {
                    background-color: #E7F2F7;
                    
                }
                
                .projectsCriteriaGroup {
                    background-color: #D1E8F2;
                }
                
                .prioritiesCriteriaGroup, .applicabilitiesCriteriaGroup {
                    background-color: #B3D4E2;
                }
            }
        }
    }
    .hidden{
        display: none;
    }
}




