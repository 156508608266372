.arrowIcon {
    font-size: 22px;
    padding-right: 8px;
    width: 24px;
    -webkit-transition: 0.15s;
    -moz-transition: 0.15s;
    -o-transition: 0.15s;
    transition: 0.15s;
    transform-origin: 13px 16px;
}

.arrowIconRotate {
    transform-origin: 13px 16px;
    transform: rotate(90deg);
}