.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: white;
}
.title{
    display: flex;
    justify-content: space-between;
    color: #49535A;
    font-size: 23px;
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;
    grid-gap: 15px;
    margin: 0 0 0 15px;
    font-family: "Oswald", sans-serif;
    width: 360px;
    min-width: 360px;
    max-width: 360px;
    margin-right: 10px;
}
#FFI{
    margin-left: 10px;
    margin-right: 20px;
    color: #618198;
    font-family: "Oswald", sans-serif;
    font-size: 48px;
    font-weight: 40px;
}
#FFI-span{
    color: #CE8D85;
}

.logo-vermont {
    margin: 0 20px;
}
.versionInfo {
    font-size: 8pt;
    white-space: pre-wrap;
}