.resiliency-header-main{
    background-color: #F5E6E0;
    font-size: 18px;
    padding: 20px;
}

.resiliency-container{
    background-color: white;
}

.resiliency-table-container{
    margin-left: 20px;
    margin-right: 20px;
    // margin-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    // padding-top: 10px;
    // padding-bottom: 10px;
}

.resiliency-table{
    width: 100%;
    margin: 20px;
    border-collapse: separate;

    tbody{
        tr{
            background-color: #EBEBEB;
            height: 35px;

        }
        
    }
}

.left-header{
    text-align: left;
}

.dropdown-button{
    display: flex;
    align-items: end;
    // margin-left: 20px;
    // transform: rotate(90deg);

    span{
        margin-right: 20px;
    }
}

.hide{
    display: none;
}

.resiliency-header{
    text-align: center;
    font-weight: 700;
}

.resiliency-table-header{
    font-weight: 700;
    text-decoration: underline;
    margin-left: 20px;
    margin-bottom: 0px;
}