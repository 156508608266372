.main-header, .wq-river-header{
    background-color: #F5E6E0;
    padding: 20px;
    margin-bottom: 0px;
}

.main-header{
    font-size: 18px;
}
.sub-unit-section{
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    div{
        display: flex;
        align-items: center;
        max-height: 30px;
        margin-top: 5px;
        margin-bottom: 5px;
        // p{
        //     margin: 0px;
        // }
    }
}

.sub-unit-header{
    font-weight: 700;
    margin-right: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.wq-stream-header{
    padding: 20px;
    background-color: #E7F2F7;
    margin-top: 0px;
    margin-bottom: 0px;
}

.wq-benefit{
    background-color: white;
}

.wq-header{
    font-size: 15px;
    margin-left: 20px;
    text-align: left;
    text-decoration: underline;
    font-weight: 700;
}

#toggle-arrow-header-container{
    display: flex;
}

.expandable-floodplain-table{
    text-align: center;
}

.sub-unit-text{
    margin-top: 0px;
    margin-bottom: 0px;
}

.floodplain-conn-row, .stream-conn-row{
    height: 30px;
    background-color: #EBEBEB;
}

.floodplain-conn-row > td, .stream-conn-row > td{
    padding-bottom: 10px;
    padding-top: 10px;

}

// .wq-benefit-table{
//     background-color: #EBEBEB;

// }