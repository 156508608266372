#map-container{
    padding: 0;
    margin: 0;
    opacity: 100%;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 998;
    pointer-events: all;
    grid-column-start: 1;
    grid-column-end: -1;
    grid-row-start: 1;
    grid-row-end: -1;
}

.esri-view .esri-view-root{
    height: 100% !important;
    width: 100% !important;
}
