.split-pane-divider{
    height: 15px;
    background: #4d4d4d;
    z-index: 10009;
    cursor: ns-resize;
    pointer-events: all;
    filter: drop-shadow(0 0 21px #000);
    border-radius: 4px;
}

.split-pane-row{
    height: 100%;
    // display: flex;
    // flex-direction: column;
    pointer-events: none;
}

.split-pane-top{
    height: 90%;
    flex: 1;
    // overflow-y: hidden;
    pointer-events: none;
    display: flex;
    justify-content: end;
    flex-direction: column;
}

.split-pane-bottom{
    pointer-events: all;
    padding-top: 10px;
    height: 100%;
    overflow-y: scroll;
    // // position: static;
    // bottom: 0;
}